<template>
  <div class="login">
    <BaseHeaderBar title="Kata sandi masuk" :showCustomerService="false" />
    <img class="login-icon" src="@/assets/img/login-icon.png" />
    <form class="login-body" @submit.prevent="onSubmit">
      <div class="input-block">
        <input
          v-model.trim="phone"
          type="tel"
          maxlength="13"
          autofocus
          placeholder="Masukkan nomor handphone dengan awalan 08"
        />
      </div>
      <div class="input-block">
        <input
          class="password"
          v-model.trim="password"
          maxlength="8"
          type="tel"
          placeholder="Masukkan 8 digit angka password"
        />
      </div>
      <div class="forget-password" @click="forgetPassword">Lupa Password？</div>
      <button type="button" class="login-btn login_button" @click="onSubmit">
        Masuk via password
      </button>
      <button type="button" class="login-btn" @click="goMessageLogin">
        Masuk via SMS
      </button>
      <button v-if="isIOS" type="button" class="login-btn" @click="goRegister">
        Daftar
      </button>
    </form>
    <a class="kf" href="mailto:pinjamanindahcs68@gmail.com"
      >CS: pinjamanindahcs68@gmail.com</a
    >
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'
import webviewBack from '@/mixins/webviewBack'

export default {
  name: 'Login',
  mixins: [webviewBack],
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {

    forgetPassword () {
      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    validate () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan 8 digit angka password')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Password salah')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password,
        apdrpVersion: '1.0'
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          const enhedensdige = sessionStorage.getItem('enhedensdige')
          const form = {
            userIdBK: res.penggunaID,
            tokenBK: res.penggunaToken,
            versionBK: '1.0',
            deviceNoBK: enhedensdige
          }
          this.addressBook()
          this.$store.commit('recordInfo', {
            type: 'userBKInfo',
            value: form
          })
          localStorage.setItem('userBKInfo', JSON.stringify(form))
          this.$router.push('/home1')
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    },
    goRegister () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }
  }
}
</script>

<style scoped lang="less">
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.login-icon {
  width: 470px;
  height: 356px;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.login-body {
  position: relative;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 42px;
}

.register {
  position: absolute;
  top: 40px;
  right: 30px;
  line-height: 50px;
  font-size: 36px;
  color: #303030;
}

.input-block {
  border-bottom: 1px solid #b3b3b3;

  &:nth-child(n + 2) {
    margin-top: 26px;
  }

  input {
    display: block;
    width: 100%;
    height: 78px;
    line-height: 78px;
    padding: 0;
    border: none;
    font-size: 30px;
    color: #333;
    background-color: transparent;

    &::placeholder {
      color: #808080;
      font-size: 26px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.forget-password {
  margin-top: 30px;
  font-size: 26px;
  color: #4d4d4d;
  margin-bottom: 50px;
}

.login-btn {
  .login-submit-btn;
  width: 100%;
  margin-top: 26px;
}
.login_button {
  background: #333;
  color: #fff;
}
.kf {
  padding-bottom: 32px;
  font-size: 26px;
  color: #4d4d4d;
  text-align: center;
}
.password {
  -webkit-text-security: disc;
}
</style>
